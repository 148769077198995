body {
  background-color: #f8f9fa;
}

.navbar {
  background-color: black;
}

.navbar-brand,
.navbar-nav .nav-link {
  color: #fff;
}

header {
  background-color: #343a40;
  color: #fff;
}

section#about,
section#services,
section#testimonials,
section#contact {
  background-color: #fff;
}

main {
  min-height: 600px;
}

footer {
  background-color: #f8f9fa;
}

.watermark {
  position: fixed;
  top: 30%;
  left: 30%;
  opacity: 0.5;
  z-index: 9999;
  pointer-events: none;
  font-size: 72pt;
  color: red;
  transform: rotate(20deg);
}